import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import { ThankYouHero } from '../components/Hero'

const ThankYou = () => {
    return (
        <Layout>
            <SEO title="Thank You" />
            <ThankYouHero />
        </Layout>
    )
}

export default ThankYou
